import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { HomepageComponent } from './admin/homepage/homepage.component';
import { DetailComponent } from './admin/instagrams/detail/detail.component';
import { LoginComponent } from './auth/login/login.component';
import { HomeComponent } from './home/home.component'
import { ProductsComponent } from './products/products.component';
import { BlogDetailComponent } from './blog-detail/blog-detail.component';
import { LayoutComponent } from './layout/layout.component';
import { GeneralLayoutComponent } from './general-layout/general-layout.component';
import { TiktokDetailComponent } from './admin/tiktoks/tiktok-detail/tiktok-detail.component';
import { YoutubeDetailComponent } from './admin/youtube/youtube-detail/youtube-detail.component';
import { YoutubeCreateComponent } from './admin/youtube/youtube-create/youtube-create.component';
import { TiktokCreateComponent } from './admin/tiktoks/tiktok-create/tiktok-create.component';
import { InstagramCreateComponent } from './admin/instagrams/instagram-create/instagram-create.component';
import { BlogComponent } from './blog/blog.component';
import { BlogAdminComponent } from './admin/blog-admin/blog-admin.component';
import { BlogAdminDetailComponent } from './admin/blog-admin-detail/blog-admin-detail.component';
import { FaqComponent } from './faq/faq.component';
import { BlogAdminCreatComponent } from './admin/blog-admin-creat/blog-admin-creat.component';
import { ContactComponent } from './contact/contact.component';
import {guardNameGuard} from "./guard-name.guard";
import { PolicyComponent } from './policy/policy.component';

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {
    path: '', component: GeneralLayoutComponent, children: [
      {path: 'home', component: HomeComponent},
      {path: 'products', component: ProductsComponent},
      {path: 'faq', component: FaqComponent},
      {path: 'policy', component: PolicyComponent},
      {path: 'contact', component: ContactComponent},
      {path: 'blogs', component: BlogComponent},
      {path: 'blogs/:id', component: BlogDetailComponent},
    ]
  },
  {
    path: 'admin', component: LayoutComponent, canActivate: [guardNameGuard], children: [
      {path: 'home', component: HomepageComponent},
      {path: 'dashboard', component: DashboardComponent},
      {path: 'blog', component: BlogAdminComponent},
      {path: 'blog/:id', component: BlogAdminDetailComponent},
      {path: 'instagrams/:id', component: DetailComponent},
      {path: 'tiktoks/:id', component: TiktokDetailComponent},
      {path: 'youtube/:id', component: YoutubeDetailComponent},
      {path: 'youtubes/create', component: YoutubeCreateComponent},
      {path: 'tiktok/create', component: TiktokCreateComponent},
      {path: 'instagram/create', component: InstagramCreateComponent},
      {path: 'blogs/create', component: BlogAdminCreatComponent},
    ]
  },
  {
    path: 'auth', component: LayoutComponent, children: [
      {path: 'login', component: LoginComponent},

    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
