import { Injectable } from '@angular/core';
import { BlogModel } from '../models/blogModel.model';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  protected apiServerPaths = environment.apiServer.paths.blog;
  constructor(
    private apiService: ApiService
  ) { }

  public getAllBlog(): Observable<BlogModel[]> {
    return this.apiService.get(this.apiServerPaths.list, {}, map(response => {
      return response;
    }));
  }

  public getBlogById(id: number): Observable<BlogModel> {
    return this.apiService.get(this.apiServerPaths.list + '/' + id, {}, map(response => {
      return response;
    }));
  }

  public updateBlog(id: number, data: any): Observable<any> {
    return this.apiService.put(this.apiServerPaths.list + '/' + id, data, {}, map(response => {
      return response;
    }));
  }

  public createBlog(data: any): Observable<any> {
    return this.apiService.post(this.apiServerPaths.create, data, {}, map(response => {
      return response;
    }));
  }
}
