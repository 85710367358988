<div class="min-h-fit flex flex-row h-full homepage">
  <app-navbar></app-navbar>
  <div class="w-full h-auto p-8 min-h-screen">
    <h1 class="border-l border-l-amber-300 border-l-[6px] pl-3 text-2xl">Homepage Setting</h1>

    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Instagram
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="pt-5">
          <a [routerLink]="['/admin/instagram/create']" class="border px-5 py-2">Create</a>
          <ul class="grid grid-cols-1 gap-4 mt-10">
            <li *ngFor="let post of listInstagramPost" class="bg-white shadow-md rounded-lg overflow-hidden flex flex-row pr-4">
              <a [routerLink]="['/admin/instagrams', post.id]" class="w-48 h-48">
                <img loading="lazy" *ngIf="post.image" src="{{post.image}}" alt="Instagram Image" class="max-w-[unset] w-[inherit] h-full object-cover">
                <img *ngIf="!post.image"src="assets/images/blank-image.jpg" alt="No Image" class="max-w-[unset] h-full object-cover">
              </a>
              <div class="p-4">
                <h2 class="text-lg font-semibold mb-2">
                  <a [routerLink]="['/admin/instagrams', post.id]" class="text-black">{{post.short_description}}</a>
                </h2>
                <p class="text-gray-700" [innerHTML]="post.description"></p>
              </div>
              <mat-slide-toggle [(ngModel)]="post.toggleValue" (ngModelChange)="changeStatus('instagram', post.id, post.toggleValue)" class="ml-auto pt-5"></mat-slide-toggle>
            </li>
          </ul>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Tiktok
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="pt-5">
          <a [routerLink]="['/admin/tiktok/create']" class="border px-5 py-2">Create</a>
          <ul class="grid grid-cols-1 gap-4 mt-10">
            <li *ngFor="let item of listTiktokPost" class="bg-white shadow-md rounded-lg overflow-hidden flex flex-row pr-4">
              <a [routerLink]="['/admin/tiktoks', item.id]" class="w-48 h-48">
                <img loading="lazy" *ngIf="item.image" src="{{item.image}}" alt="Instagram Image" class="max-w-[unset] w-[inherit] h-full object-cover">
                <img *ngIf="!item.image"src="assets/images/blank-image.jpg" alt="No Image" class="max-w-[unset] h-full object-cover">
              </a>
              <div class="p-4">
                <h2 class="text-lg font-semibold mb-2">
                  <a [routerLink]="['/admin/tiktoks', item.id]" class="text-black">{{item.title}}</a>
                </h2>
                <p class="text-gray-700" [innerHTML]="item.description"></p>
              </div>
              <mat-slide-toggle [(ngModel)]="item.toggleValue" (ngModelChange)="changeStatus('tiktok', item.id, item.toggleValue)" class="ml-auto pt-5"></mat-slide-toggle>
            </li>
          </ul>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Youtube
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="pt-5">
          <a [routerLink]="['/admin/youtubes/create']" class="border px-5 py-2">Create</a>
          <ul class="grid grid-cols-1 gap-4 mt-10">
            <li *ngFor="let item of listYoutubePost" class="bg-white shadow-md rounded-lg overflow-hidden flex flex-row pr-4">
              <a [routerLink]="['/admin/youtube', item.id]" routerLink="" class="w-48 h-48">
                <img loading="lazy" *ngIf="item.image" src="{{item.image}}" alt="Instagram Image" class="max-w-[unset] w-[inherit] h-full object-cover">
                <img *ngIf="!item.image"src="assets/images/blank-image.jpg" alt="No Image" class="max-w-[unset] h-full object-cover">
              </a>
              <div class="p-4">
                <h2 class="text-lg font-semibold mb-2">
                  <a [routerLink]="['/admin/youtube', item.id]"  class="text-black">{{item.title}}</a>
                </h2>
                <p class="text-gray-700" [innerHTML]="item.description"></p>
              </div>
              <mat-slide-toggle [(ngModel)]="item.toggleValue" (ngModelChange)="changeStatus('youtube', item.id, item.toggleValue)" class="ml-auto pt-5"></mat-slide-toggle>
            </li>
          </ul>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
