import { Component } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { MENU } from './share/constant';
import { NgForOf, NgIf } from '@angular/common';
import {environment} from "../environments/environment";
@Component({
  standalone: true,
	imports: [RouterOutlet, NgForOf, NgIf, RouterLink],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor() {
  }
}
