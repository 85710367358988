import { Component } from '@angular/core';
import { NavbarComponent } from '../component/navbar/navbar.component';
import { BlogService } from '../services/blog.service';
import { BlogModel } from '../models/blogModel.model';
import { NgForOf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { filter, take } from 'rxjs';
import {SeoService} from "../services/seo.service";

@Component({
  standalone: true,
  imports: [NavbarComponent, NgForOf, RouterLink],
  selector: 'app-blog-admin',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent {

  listBlog: BlogModel[] = []

  constructor(
    private blogService: BlogService,
    private seo: SeoService
  ) {
    this.seo.generateTags({
      title: 'Blog Page',
      description: 'VN hair extensions factory.  Anbi is a full-cycle hair factory that starts with the purchase of donor hair and extensions. ',
      slug: 'blogs'
    })
    this.getAllBlog()
  }


  getAllBlog(): void {
    this.blogService.getAllBlog()
      .subscribe((res: any) => {
        if (res.success) {
          this.listBlog = res.data
          this.listBlog = this.listBlog
            .filter((item: BlogModel) => item.is_display === 1)
            .sort((a: BlogModel, b: BlogModel) => b.id - a.id);
          this.listBlog.forEach((item: any) => {
            if (item.is_display === 0) {
              item.toggleValue = false;
            }
            if (item.is_display === 1) {
              item.toggleValue = true;
            }
          })
        }
      })
  }
}
