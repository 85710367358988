<div class="container mx-auto pb-8">
  <section class="bg-white py-10 px-4">
    <div class="text-center font-semibold text-[32px] mb-8">Blog</div>
    <div class="grid sm:grid-cols-2 grid-cols-1 lg:grid-cols-3 gap-5 mb-10">
      <a title="read blog detail" routerLink="/blogs/{{item.id}}" *ngFor="let item of listBlog" class="shadow-xl cursor-pointer overflow-hidden">
        <img loading="lazy" class="w-full h-auto max-h-[400px] overflow-hidden duration-500 hover:scale-105" src="{{item.image ? item.image : 'assets/images/blank-image.jpg'}}" alt="{{item.title}}"/>
        <div class="px-8 mt-6 pb-8">
          <h2 class="w-full text-center capitalize text-[26px] font-semibold mt-6 mb-4">{{item.title}}</h2>
          <div>
            {{item.short_description}}
          </div>
        </div>
      </a>
    </div>
  </section>
</div>
