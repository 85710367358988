<div class="bg-white p-8 rounded shadow-2xl sm:w-full mx-auto w-full md:w-96 absolute transform left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
  <h1 class="text-2xl font-semibold mb-4 text-center">Login</h1>
  <form action="/admin/login" method="POST">
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-medium mb-2">Email</label>
      <input [(ngModel)]="loginData.email" type="text" id="emamil" name="email" class="border border-gray-300 px-3 py-2 rounded w-full focus:ring focus:ring-blue-300" required>
    </div>
    <div class="mb-6">
      <label for="pwd" class="block text-gray-700 text-sm font-medium mb-2">Password</label>
      <input [(ngModel)]="loginData.pwd" type="password" id="pwd" name="pwd" class="border border-gray-300 px-3 py-2 rounded w-full focus:ring focus:ring-blue-300" required>
    </div>
    <button (click)="login()" class="bg-blue-500 text-white px-4 py-2 rounded w-full hover:bg-blue-600 transition">Log in</button>
  </form>
</div>
