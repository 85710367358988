<div class="flex bg-gray-100 h-full">
  <div class="bg-gray-800 w-64 min-h-auto">
    <div class="flex items-center justify-center mt-10">
      <h1 class="text-white text-xl font-extrabold">Anbihair</h1>
    </div>
    <nav class="mt-10">
<!--      <a [routerLink]="['/admin/dashboard']" class="cursor-pointer flex items-left text-gray-300 hover:bg-gray-700 hover:text-white px-4 py-2">-->
<!--        <span class="ml-2">Dashboard</span>-->
<!--      </a>-->
      <a [routerLink]="['/admin/home']" title="go to anbihair.com"class="cursor-pointer flex items-center text-gray-300 hover:bg-gray-700 hover:text-white px-4 py-2">
        <span class="ml-2">Homepage</span>
      </a>
<!--      <a routerLink="#" class="cursor-pointer flex items-center text-gray-300 hover:bg-gray-700 hover:text-white px-4 py-2">-->
<!--        <span class="ml-2">Products</span>-->
<!--      </a>-->
      <a [routerLink]="['/admin/blog']" class="cursor-pointer flex items-center text-gray-300 hover:bg-gray-700 hover:text-white px-4 py-2">
        <span class="ml-2">Blogs</span>
      </a>
    </nav>
  </div>
</div>
