import { Component } from '@angular/core';
import { BlogService } from '../../services/blog.service';
import { BlogModel } from '../../models/blogModel.model';
import { NavbarComponent } from '../../component/navbar/navbar.component';
import { RouterLink } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { NgForOf, NgIf } from '@angular/common';

@Component({
  standalone: true,
  imports: [
    NavbarComponent,
    RouterLink,
    MatSlideToggleModule,
    FormsModule,
    NgForOf,
    NgIf
  ],
  selector: 'app-blog-admin',
  templateUrl: './blog-admin.component.html',
  styleUrls: ['./blog-admin.component.scss']
})
export class BlogAdminComponent {

  listBlog: BlogModel[] = []
  toggleValue = false;
  constructor(
    private blogService: BlogService
  ) {
    this.getAllBlog()
  }
  getAllBlog(): void {
    this.blogService.getAllBlog().subscribe((res: any) => {
      if (res.success) {
        this.listBlog = res.data
        // this.listBlog = this.listBlog.filter((item: BlogModel) => item.is_display === 1)
        this.listBlog.forEach((item: any) => {
          if (item.is_display === 0) {
            item.toggleValue = false;
          }
          if (item.is_display === 1) {
            item.toggleValue = true;
          }
        })
      }
    })
  }

  changeStatus(id: number, newStatus: boolean) {
    let data: any = {}
    if (newStatus) {
      data.is_display = 1;
    }

    if (!newStatus) {
      data.is_display = 0;
    }
    this.blogService.updateBlog(id, data).subscribe((res: any) => {

    })
  }
}
