import { NgForOf, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { MENU } from '../share/constant';

@Component({
  standalone: true,
  imports: [RouterOutlet, NgForOf, NgIf, RouterLink],
  selector: 'app-general-layout',
  templateUrl: './general-layout.component.html',
  styleUrls: ['./general-layout.component.scss']
})
export class GeneralLayoutComponent {

	listMenu: any = MENU;
  mobileMenuOpen = false;
  constructor() {
  }
  public toggleMobileMenu(): void {
    this.mobileMenuOpen = !this.mobileMenuOpen;
  }

  public goTo(type: number, link: any): void {
    if (type === 1) {
      const phoneNumber = link;
      const message = 'Hi';
      const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
      window.open(whatsappUrl, '_blank');
      return;
    }
    window.open(link, '_blank');
  }

  public closeMenu(): void {
    this.mobileMenuOpen = false
  }
}
