import { Injectable } from '@angular/core';
import { YoutubeModel } from '../models/youtubeModel.model';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class YoutubeService {

  protected apiServerPaths = environment.apiServer.paths.youtube;
  constructor(
    private apiService: ApiService
  ) { }

  public getAllPost(): Observable<YoutubeModel[]> {
    return this.apiService.get(this.apiServerPaths.list, {}, map(response => {
      return response;
    }));
  }

  public getPostById(id: number): Observable<YoutubeModel> {
    return this.apiService.get(this.apiServerPaths.list + '/' + id, {}, map(response => {
      return response;
    }));
  }

  public updateYoutube(id: number, data: any): Observable<any> {
    return this.apiService.put(this.apiServerPaths.list + '/' + id, data, {}, map(response => {
      return response;
    }));
  }

  public createYoutube(data: any): Observable<any> {
    return this.apiService.post(this.apiServerPaths.create, data, {}, map(response => {
      return response;
    }));
  }
}
