import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {ActivatedRoute, Router} from '@angular/router';
import { Subscription } from 'rxjs';
import { NavbarComponent } from 'src/app/component/navbar/navbar.component';
import { YoutubeModel } from 'src/app/models/youtubeModel.model';
import { YoutubeService } from 'src/app/services/youtube.service';
import {UploadService} from "../../../services/upload.service";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {QuillModule} from "ngx-quill";

@Component({
  standalone: true,
  imports: [
    NavbarComponent,
    FormsModule,
    NgIf,
    QuillModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule
  ],
  selector: 'app-youtube-detail',
  templateUrl: './youtube-detail.component.html',
  styleUrls: ['./youtube-detail.component.scss']
})
export class YoutubeDetailComponent {

  private routeSub: Subscription;
  postId: number = 0;
  post: YoutubeModel = {
    description: '',
    id: 0,
    image: '',
    toggleValue: false,
    is_display: 0,
    video_url: '',
    title: ''
  }
  selectedFile!: File;
  fileName = '';
  @Input()
  requiredFileType: string = '';
  isLoading = false;

  uploadProgress:number = 0;
  uploadSub!: Subscription;
  toggleValue = false;
  constructor(
    private route: ActivatedRoute,
    private youtubeService: YoutubeService,
    private router: Router,
    private uploadService: UploadService,

  ) {
    this.routeSub = this.route.params.subscribe(params => {
      this.postId = params['id']
      this.getPostById(this.postId)
    });
  }

  onFileSelected(event: any) {
    this.isLoading = true;
    const file:File = event.target.files[0];

    if (file) {
      this.fileName = file.name;
      const formData = new FormData();
      formData.append("thumbnail", file);
      this.uploadService.uploadImageToDrive(formData).subscribe((res: any) => {
        this.post.image = res.imageUrl;
        this.isLoading = false
      })
    }
  }

  getPostById(postId: number) {
    this.youtubeService.getPostById(postId).subscribe((res: any) => {
      if (res.success) {
        this.post = res.data;
        if (this.post.is_display === 0) {
          this.toggleValue = false;
        }
        if (this.post.is_display === 1) {
          this.toggleValue = true;
        }
      }
    })
  }

  submitForm(): void {
    if (this.toggleValue) {
      this.post.is_display = 1;
    }

    if (!this.toggleValue) {
      this.post.is_display = 0;
    }
    this.youtubeService.updateYoutube(this.postId, this.post).subscribe((res: any) => {
      if(res.success) {
        this.router.navigate(['/admin/home']);
      }
    })
  }
}
