<header class="pt-8 px-4 md:px-8 flex flex-row justify-between items-center gap-4 pb-5 border-b border-b-gray-5">
  <div class="hidden md:flex flex-row items-center">
    <img class="w-[25px] mr-2"
         alt="icon mail"
         src="assets/images/icons/mail.svg"/>
    <div class="flex flex-col">
      <span><a title="email to mr anbi" href="mailto:anbihair@gmail.com">anbihair@gmail.com</a></span>
      <span>Email</span>
    </div>
  </div>
  <div class="flex flex-col md:flex-row items-center">
    <img class="w-[25px] mr-2 flex inline-" alt="icon clock" src="assets/images/icons/clock.svg"/>
    <div class="flex flex-col items-center md:items-start">
      <span class="whitespace-nowrap">0.9 a.m – 0.5 p.m</span>
      <span class="whitespace-nowrap">Monday to Friday</span>
    </div>
  </div>
  <div class="flex flex-col md:flex-row items-center">
    <img class="w-[25px] mr-2" alt="phone icon" src="assets/images/icons/wapps.svg"/>
    <div class="flex flex-col items-center md:items-start">
      <span class="whitespace-nowrap cursor-pointer">
        <span (click)="goTo(1, '84964197276')">+84 964197276</span>
        /<span (click)="goTo(1, '84333284471')">+84 333284471</span>
      </span>
      <span>WhatsApp</span>
    </div>
  </div>
  <div class="hidden md:flex flex-row items-center justify-end w- h-">
    <a class="cursor-pointer" href="#" (click)="goTo(0, 'https://www.instagram.com/anbihairfactoryvn')" target="_blank">
      <img alt="icon instagram" class="w-[25px] mr-3" src="assets/images/icons/ig.svg"/>
    </a>
    <a class="cursor-pointer" href="#" target="_blank">
      <img alt="icon facebook-icon" class="w-[25px] mr-3" src="assets/images/icons/fb.svg"/>
    </a>
    <a class="cursor-pointer" href="#" (click)="goTo(0, 'https://www.youtube.com/@Anbihair')" target="_blank">
      <img alt="icon youtube" class="w-[25px] mr-3" src="assets/images/icons/yt.svg"/>
    </a>
    <a class="cursor-pointer" href="#" (click)="goTo(0, 'https://www.tiktok.com/@anbihairfactoryvn')" target="_blank">
      <img alt="icon youtube" class="w-[20px] mr-3" src="assets/images/icons/tiktok.svg"/>
    </a>
    <a class="cursor-pointer" href="#" (click)="goTo(1, '84964197276')" target="_blank">
      <img alt="whatapps icon" class="w-[25px] mr-3" src="assets/images/icons/what-app.svg"/>
    </a>
  </div>
</header>
<nav class="px-4 bg-white">
  <div class="container flex items-center justify-between py-4 mx-auto">
    <div class="flex items-center">
      <span class="ml-2 text-xl font-semibold">
        <a routerLink="/">
          <img width="200" src="assets/images/anbihair_logo-removebg.png" alt="logo image" />
        </a>
      </span>
    </div>
    <!-- Mobile Menu Toggle -->
    <button (click)="toggleMobileMenu()" class="text-gray-600 md:hidden hover:text-gray-800 focus:outline-none">
      <img width="30" src="assets/images/icons/menu.svg" alt="menu icon"/>
    </button>
    <!-- Mobile menu -->
    <div id="mobile-menu" [class.hidden]="!mobileMenuOpen" class="lg:hidden absolute left-0 z-40 w-full top-44">
      <ul class="bg-white text-black">
          <li *ngFor="let item of listMenu" class="group  hover:bg-gray-200">
            <a routerLink="{{item.link}}" (click)="closeMenu()" class="hover:no-underline block py-2 px-4 hover:underline">{{ item.label }}</a>
          </li>
      </ul>
  </div>
    <ul class="hidden md:flex md:space-x-6">
      <li *ngFor="let item of listMenu" class="group">
        <a routerLink="{{item.link}}" class="cursor-pointer text-gray-600 text-base hover:text-gray-800">{{ item.label }}</a>
        <ul *ngIf="item?.childrens"
            class="absolute max-w-[200px] hidden py-2 z-10 space-y-1 bg-white border border-gray-200 group-hover:block">
          <li *ngFor="let item2 of item?.childrens, let i = index" class="hover:bg-gray-200 overflow-x-hidden">
            <a routerLink="{{item2.link}}" class="cursor-pointer block text-gray-600 text-base px-4 py-2 w-max">
              {{ item2.label }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</nav>
<router-outlet></router-outlet>
<footer class="text-center text-white py-4 bg-[#0B162A] px-4">
  <div class="flex flex-col sm:flex-row gap-4 w-full">
    <div class="flex flex-col w-full sm:w-[35%]">
      <h3 class="font-bold text-2xl text-white text-left mb-3">About Us</h3>
      <p class="text-left">Anbihair is a full-cycle hair factory that starts with the purchase of “donor hair” and ends with sales of ready-made hair extensions. We offer hair extensions only the highest quality category.</p>
      <div class="flex flex-row">
        <div class="hidden md:flex flex-row items-center justify-end w- h-">
          <a class="cursor-pointer" href="#" (click)="goTo(0, 'https://www.instagram.com/anbihairfactoryvn')" target="_blank">
            <img alt="icon instagram" class="w-[20px] mr-3" src="assets/images/icons/igft.svg"/>
          </a>
          <a class="cursor-pointer" href="#" (click)="goTo(0, 'https://www.tiktok.com/@anbihairfactoryvn')" target="_blank">
            <img alt="icon instagram" class="w-[20px] mr-3" src="assets/images/icons/tiktikft.svg"/>
          </a>
          <a class="cursor-pointer" href="#" (click)="goTo(0, 'https://www.youtube.com/@Anbihair')" target="_blank">
            <img alt="icon youtube" class="w-[20px] mr-3" src="assets/images/icons/ytft.svg"/>
          </a>
          <a class="cursor-pointer" href="#" target="_blank">
            <img alt="icon facebook-icon" class="w-[20px] mr-3" src="assets/images/icons/fbft.svg"/>
          </a>
          <a class="cursor-pointer" href="#" (click)="goTo(1, '84964197276')" target="_blank">
            <img alt="whatapps icon" class="w-[20px] mr-3" src="assets/images/icons/what-appft.svg"/>
          </a>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-full sm:w-[35%]" mr-3>
      <h3 class="font-bold text-2xl text-white text-left mb-3">Contact Us</h3>
      <ul>
        <li class="flex flex-row"><img class="w-5 mr-3 mb-2" src="assets/images/icons/phone.svg" alt="phone icon" /><span class="text-left">+84 964197276 / +84 333284471</span></li>
        <li class="flex flex-row"><img class="w-5 mr-3 mb-2" src="assets/images/icons/mailft.svg" alt="mail icon" /><span class="text-left">anbihair@gmail.com</span></li>
        <li class="flex flex-row"><img class="w-5 mr-3 mb-2" src="assets/images/icons/location.svg" alt="location icon" /><span class="text-left">Anbi hair factory, Dong Xuat, Dong Tho, Yen Phong, Bac Ninh</span></li>
        <li class="flex flex-row"><img class="w-5 mr-3 mb-2" src="assets/images/icons/calendar.svg" alt="calendar icon" /><span class="text-left">Mon-Fri : 9.00 a.m. – 5.00 p.m.</span></li>
      </ul>
    </div>
    <div class="flex flex-col w-full sm:w-[20%]">
      <h3 class="font-bold text-2xl text-white text-left mb-3">Production</h3>
      <ul>
        <li class="text-left mb-2">
          <a [routerLink]="['/products']">Machine Weft Hair</a>
        </li>
        <li class="text-left mb-2">
          <a [routerLink]="['/products']">Closure & Frontal</a></li>
        <li class="text-left mb-2">
          <a [routerLink]="['/products']">Wigs</a></li>
        <li class="text-left mb-2">
          <a [routerLink]="['/products']">Hair Extentions</a></li>
        <li class="text-left mb-2">
          <a [routerLink]="['/products']">Color Chart & Texture</a></li>
      </ul>
    </div>
    <div class="flex flex-col w-full sm:w-[10%]">
      <h3 class="font-bold text-2xl text-white text-left mb-3">Link</h3>
      <ul>
        <li class="text-left"><a [routerLink]="['/']"href="#">Home</a></li>
        <li class="text-left"><a [routerLink]="['/about-us']" href="#">About</a></li>
        <li class="text-left"><a [routerLink]="['/products']" href="#">Product</a></li>
        <li class="text-left"><a [routerLink]="['/blogs']"href="#">Blog</a></li>
        <li class="text-left"><a [routerLink]="['/contact']"href="#">Contact</a></li>
        <li class="text-left"><a [routerLink]="['/faq']"href="#">FAQ</a></li>
      </ul>
    </div>
  </div>
</footer>
