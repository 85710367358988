<div class="container mx-auto pb-8">
  <section class="bg-white py-10 px-4">
    <div class="text-center font-semibold text-[32px] mb-8">Questions and answers</div>
    <p>Here you can get answers to frequently asked questions.</p>
    <mat-accordion>
      <mat-expansion-panel class="shadow-none	" (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header class="bg-white">
          <mat-panel-title>
            <div class="text-black">Where do your products originally come from?</div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        Our products are sourced from reliable suppliers who have been purchasing directly from individuals in Vietnam, our home country.
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Can I buy virgin hair?
          </mat-panel-title>
        </mat-expansion-panel-header>
        Yes, absolutely.
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Does your product have grades?
          </mat-panel-title>
        </mat-expansion-panel-header>
        Yes, we have three types of hair: Raw, Baby, and Virgin hair. Raw and Baby hair are the best quality. Raw hair is unprocessed and lasts up to 10 years. Virgin hair lasts 1-3 years with proper care. Baby hair is soft and thin, selected from raw hair.
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Do you sell double drawn?
          </mat-panel-title>
        </mat-expansion-panel-header>
        No, we don't sell double drawn hair as we use single donor hair which is naturally full.
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            What is the quality of your hair?
          </mat-panel-title>
        </mat-expansion-panel-header>
        We use top-quality raw material and a unique dyeing technology. Our extensions have intact cuticles running in one direction, ensuring longevity and tangle-free use.
      </mat-expansion-panel>

      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Do you provide a guarantee?
          </mat-panel-title>
        </mat-expansion-panel-header>
        We guarantee our product won't tangle and can last for 6 months with proper care. It's reusable and can last up to 2 years.
      </mat-expansion-panel>

      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            What is the minimum quantity to make an order?
          </mat-panel-title>
        </mat-expansion-panel-header>
        The minimum order is 3 bundles.
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            How do I make an order?
          </mat-panel-title>
        </mat-expansion-panel-header>
        Contact us via WhatsApp with all the necessary details.
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Is it possible to get samples?
          </mat-panel-title>
        </mat-expansion-panel-header>
        Yes, we recommend buying a small amount before placing a full order, sufficient for one full extension.
      </mat-expansion-panel>

      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            How long does it take to get the order ready?
          </mat-panel-title>
        </mat-expansion-panel-header>
        The time varies depending on factors such as color, length, and quantity. Generally, it takes up to 2-3 weeks to produce the extensions before shipping.
      </mat-expansion-panel>

      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            How long does shipping take?
          </mat-panel-title>
        </mat-expansion-panel-header>
        Shipping usually takes about 3-4 business days.
      </mat-expansion-panel>

      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            What shipping companies do you use?
          </mat-panel-title>
        </mat-expansion-panel-header>
        We use FedEx, UPS, and DHL.
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Can I return the extensions?
          </mat-panel-title>
        </mat-expansion-panel-header>
        If there's an issue with the hair, contact our manager for assistance. We'll assess and offer a solution. If it's a quality issue, we'll replace or refund upon inspection.
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            How should I care for your products?
          </mat-panel-title>
        </mat-expansion-panel-header>
        Treat them like your own hair, using conditioner and avoiding excessive heat.
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Can I bleach or color your products later?
          </mat-panel-title>
        </mat-expansion-panel-header>
        We sell ready-to-use products and don't recommend bleaching or coloring, as it may affect the result and void our guarantee.
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Do you offer distribution?
          </mat-panel-title>
        </mat-expansion-panel-header>
        No, but you can purchase our products and sell them under your own brand.
      </mat-expansion-panel>
    </mat-accordion>
  </section>
</div>
