<div class="h-fit flex flex-row h-full homepage">
  <app-navbar></app-navbar>
  <div class="bg-gray-100 min-h-screen p-8 w-full overflow-auto">
    <div class="w-full mx-auto">
      <h1 class="text-3xl font-bold mb-6">Instagram Post Detail</h1>
      <div class="bg-white shadow-md rounded-lg overflow-hidden">
        <div class="p-4">
            <div class="flex flex-col justify-start items-start mb-4">
              <p class="text-gray-700 font-bold text-xl mr-3 mb-2">Short Description</p>
              <input
                id="short_description"
                name="short_description"
                [(ngModel)]="post.short_description"
                class="border w-full border-gray-500 focus-visible:border-gray-500 rounded-xl h-9 px-2"/>
            </div>
            <h2 class="text-gray-700 font-bold text-xl mb-2">Image</h2>
            <div class="flex flex-row items-center">
              <img loading="lazy" *ngIf="post.image" id="image" name="image" src="{{ post.image }}" alt="Instagram Image"
                   class="w-auto h-48 object-cover">
              <img *ngIf="!post.image" src="assets/images/blank-image.jpg" alt="No Image"
                   class="w-auto h-48 object-cover">
              <div class="ml-8 w-48 h-48" id="uploaded-image-container"></div>
            </div>
            <input class="" name="image" (change)="onFileSelected($event)" type="file" id="image-input" accept="image/*" #fileUpload>
            <mat-spinner color="accent"  *ngIf="isLoading"></mat-spinner>
            <div class="file-upload">

               {{fileName || "No file uploaded yet."}}

                <button mat-mini-fab color="primary" class="upload-btn"
                  (click)="fileUpload.click()">
                    Change Image
                </button>
            </div>

            <div class="flex flex-col justify-start items-start mb-4 mt-4">
              <p class="text-gray-700 font-bold text-xl mr-3 mb-2">Link instagram</p>
              <input name="link"
                     [(ngModel)]="post.url"
                     (ngModelChange)="fetchInstagramData()"
                     class="border w-full border-gray-500 focus-visible:border-gray-500 rounded-xl h-9 px-2"/>
            </div>
            <div class="flex flex-row gap-4">
              <div class="flex flex-row text-base">Likes: {{post.likes}} <img class="ml-3" src="assets/images/icons/heart.svg" alt="" /></div>
              <div class="flex flex-row text-base">Comments: {{post.comment}} <img class="ml-3" src="assets/images/icons/comment.svg" alt="" /></div>
              <div *ngIf="post.view !== '0'" class="flex flex-row text-base">Views: {{post.view}} <img class="ml-3" src="assets/images/icons/view.svg" alt="" /></div>
            </div>
            <div class="flex flex-row justify-start items-start mb-4 mt-4">
              <span>OFF</span>
              <mat-slide-toggle [(ngModel)]="toggleValue" class="mx-5"></mat-slide-toggle>
              <span>ON</span>
            </div>
            <div class="flex flex-col justify-start items-starts mt-4">
              <p class="text-gray-700 font-bold text-xl mr-3 mb-2">Description</p>
              <quill-editor [styles]="{ height: '400px' }" [(ngModel)]="post.description"></quill-editor>

              <input type="hidden" id="editor-content" name="editorContent">
            </div>
            <div>
              <button class="mt-3 mr-3 mb-4 border border-gray-500 tex-base rounded-xl px-3 py-2">Cancel
              </button>
              <button type="button"
                      (click)="submitForm()"
                      class="mt-3 mb-4 border border-gray-500 tex-base rounded-xl px-3 py-2">Save
              </button>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
