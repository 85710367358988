import { Component } from '@angular/core';
import { NavbarComponent } from '../../component/navbar/navbar.component';
import { Router, RouterLink } from '@angular/router';
import { InstagramService } from '../../services/instagram.service';
import { NgForOf, NgIf } from '@angular/common';
import { InstagramModel } from '../../models/instagramModel.model';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import {MatExpansionModule} from '@angular/material/expansion';
import { TiktokService } from 'src/app/services/tiktok.service';
import { TiktokModel } from 'src/app/models/tiktokModel.model';
import { YoutubeModel } from 'src/app/models/youtubeModel.model';
import { YoutubeService } from 'src/app/services/youtube.service';

@Component({
  standalone: true,
  imports: [NavbarComponent, NgForOf, RouterLink, MatSlideToggleModule, FormsModule, MatExpansionModule, NgIf],
  selector: 'app-home',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent {

  listInstagramPost: InstagramModel[] = [];
  listTiktokPost: TiktokModel[] = [];
  listYoutubePost: YoutubeModel[] = [];
  toggleValue = false;
  panelOpenState = false;
  constructor(
    private instagramService: InstagramService,
    private tiktokService: TiktokService,
    private youtubeService: YoutubeService,
    private router: Router
  ) {
    this.getAllInstagramPost();
    this.getAllTiktokPost();
    this.getAllYoutubePost();
  }

  getAllInstagramPost(): void {
    this.instagramService.getAllPost().subscribe((res: any) => {
      if (res.success) {
        this.listInstagramPost = res.data
        this.listInstagramPost.forEach((item: any) => {
          if (item.is_display === 0) {
            item.toggleValue = false;
          }
          if (item.is_display === 1) {
            item.toggleValue = true;
          }
        })
      }
    })
  }

  getAllTiktokPost(): void {
    this.tiktokService.getAllPost().subscribe((res: any) => {
      if (res.success) {
        this.listTiktokPost = res.data
        this.listTiktokPost.forEach((item: any) => {
          if (item.is_display === 0) {
            item.toggleValue = false;
          }
          if (item.is_display === 1) {
            item.toggleValue = true;
          }
        })
      }
    })
  }

  getAllYoutubePost(): void {
    this.youtubeService.getAllPost().subscribe((res: any) => {
      if (res.success) {
        this.listYoutubePost = res.data
        this.listYoutubePost.forEach((item: any) => {
          if (item.is_display === 0) {
            item.toggleValue = false;
          }
          if (item.is_display === 1) {
            item.toggleValue = true;
          }
        })
      }
    })
  }

  changeStatus(type: string, id: number, newStatus: boolean) {
    let data: any = {}
    if (newStatus) {
      data.is_display = 1;
    }

    if (!newStatus) {
      data.is_display = 0;
    }
    if (type === 'instagram') {
      this.instagramService.updateInstagram(id, data).subscribe((res: any) => {
      })
    }
    if (type === 'tiktok') {
      this.tiktokService.updateTiktok(id, data).subscribe((res: any) => {
      })
    }
    if (type === 'youtube') {
      this.youtubeService.updateYoutube(id, data).subscribe((res: any) => {
      })
    }
  }

  protected readonly isFinite = isFinite;
}
