import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  protected apiServerPaths = environment.apiServer.paths.upload;

  constructor(
    private apiService: ApiService
  ) { }

  public uploadImageToDrive(image: any): Observable<any> {
    return this.apiService.post(this.apiServerPaths.image, image, {}, map(response => {
      return response;
    }));
  }
}
