import { Component } from '@angular/core';
import { NavbarComponent } from '../component/navbar/navbar.component';
import { BlogService } from '../services/blog.service';
import { ActivatedRoute } from '@angular/router';
import { BlogModel } from '../models/blogModel.model';
import { Subscription } from 'rxjs';
import {QuillViewComponent, QuillViewHTMLComponent} from "ngx-quill";

@Component({
  standalone: true,
  imports: [NavbarComponent, QuillViewComponent, QuillViewHTMLComponent],
  selector: 'app-blog-admin-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss']
})
export class BlogDetailComponent {
  private routeSub: Subscription;
  blogId: number = 0;
  blog: BlogModel = {
    content: '',
    id: 0,
    image: '',
    is_display: 0,
    toggleValue: false,
    short_description: '',
    tags_id: '',
    title: '',
    user_id: '',
    created_at: ''
  }
  constructor(
    private blogService: BlogService,
    private route: ActivatedRoute,
  ) {
    this.routeSub = this.route.params.subscribe(params => {
      this.blogId = params['id']
      this.getPostById(this.blogId)
    });
  }

  getPostById(postId: number) {
    this.blogService.getBlogById(postId).subscribe((res: any) => {
      if (res.success) {
        this.blog = res.data;
      }
    })
  }
}
