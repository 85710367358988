<div class="pb-8">
  <!--    class="flex flex-col px-6 mb-10 justify-center h-[550px] bg-no-repeat bg-cover bg-[url('assets/images/banner.webp')]">-->
  <section
    class="relative flex flex-col px-0 mb-10 justify-center overflow-hidden h-fit sm:h-[550px] bg-no-repeat bg-cover">
    <owl-carousel-o class="w-full" [options]="customOptions2">
      <!-- <ng-template carouselSlide>
        <div class="slider-slide bg-gradient-to-r from-black from-70% to-white">
          <img src="assets/images/cover/1.jpg" loading="lazy"
               alt="Image 1" class="w-full h-[350px] opacity-70 h-full object-cover bg-gradient-to-r from-blue-500 to-green-500">
        </div>
      </ng-template> -->
      <ng-template carouselSlide>
        <div class="slider-slide bg-gradient-to-r from-black from-70% to-white">
          <img src="assets/images/cover/4.jpg" loading="lazy"
               alt="Image 4" class="w-full opacity-70 h-full relative md:top-[30px] lg:top-[110px] xl:top-[205px] 2xl:top-[300px] from-blue-500 to-green-500">
        </div>
      </ng-template>>
    </owl-carousel-o>
    <div class="absolute z-30 px-6 flex flex-col">
      <div class="text-[40px] mb-5 text-white leading-10 font-bold">PREMIUM RAW HAIR FACTORY IN VIETNAM</div>
      <div class="text-[23px] mb-5 text-white">100% natural, single donor, human hair.</div>
      <a href="#" class="flex flex-row items-center bg-[#FF6501] px-4 py-3 w-fit">
        <span class="text-white font-[15px]">Learn more about our factory</span>
        <img class="w-[25px] mr-2 ml-2" alt="icon right icon" src="assets/images/icons/right.svg"/>
      </a>
    </div>
  </section>
  <div class="container mx-auto">
    <section class="flex flex-col sm:flex-row gap-4 px-4">
      <div class="flex flex-col basis-auto flex-grow flex-shrink w-full sm:w-1/2">
        <h2 class="mb-4 text-2xl font-semibold">PREMIUM RAW HAIR FACTORY IN VIETNAM</h2>
        <p class="text-gray-700">
          Vietnam hair extensions factory.
          We are located in VietNam.
          Anbi is a full-cycle hair factory that starts with the purchase of “donor hair” and ends with sales of
          ready-made hair extensions. We offer hair extensions only the highest quality category.
          <br/>
          The factory has been specializing in the manufacture of EXCLUSIVE high-quality hair for all types of extension
          technologies for more than 10 years.
          <br/>
          We use only the best materials and the best technology. We use only human hair to create our products. Anbi
          hair is premium raw hair factory and the largest wholesale supplier in Vietnam.
        </p>
        <div class="sm:flex sm:flex-col lg:flex-row mt-8">
          <a href="#" class="flex flex-row w-full sm:w-72 text-sm text-white mr-8 items-center bg-[#FF6501] px-4 py-3">
            Learn more about our factory
            <img class="w-8" src="assets//images/icons/right.svg" alt="icon right"/>
          </a>
          <a href="https://www.youtube.com/@Anbihair" target="_blank"
             class="flex flex-row text-sm w-full sm:w-72 text-white items-center bg-black px-4 py-3">
            View our YouTube Channel
            <img class="w-8 ml-4" src="assets/images/icons/yt-black.svg" alt="youtube black"/>
          </a>
        </div>
      </div>
      <div class="mt-4 flex flex-col basis-auto flex-grow-0 w-full sm:w-1/2 flex-shrink lg:mt-0">
        <div class="flex justify-end overflow-hidden">
          <div class="relative w-full max-w-lg">
            <!-- Slider Container -->
            <owl-carousel-o [options]="customOptions">
              <ng-template carouselSlide>
                <div class="slider-slide">
                  <img src="assets/images/slider1.jpg" loading="lazy"
                       alt="Image 1" class="w-full h-full object-cover">
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="slider-slide">
                  <img src="assets/images/slider2.jpg" loading="lazy"
                       alt="Image 1" class="w-full h-full object-cover">
                </div>
              </ng-template>
            </owl-carousel-o>
          </div>
        </div>
      </div>
    </section>
    <app-products></app-products>
    <section class="bg-white py-10 px-4">
      <div class="text-center font-semibold text-[32px] mb-8">Our Instagram</div>
      <div class="grid sm:grid-cols-2 grid-cols-1 lg:grid-cols-3 gap-5 mb-10">
        <a href="{{ item.url }}" target="_blank" *ngFor="let item of listInstagramPost"
           class="shadow-xl relative h-[400px] group hover:before:opacity-70">
          <div class="overflow-hidden max-h-[400px]">
            <img loading="lazy"
                 class="transition-transform transform-gpu group-hover:scale-105 duration-700 w-full h-auto"
                 src="{{ item.image }}" alt="{{item.short_description}}"/>
          </div>
          <div
            class="px-8 pb-8 transform transition-transform transform-gpu transition-all duration-700 group-hover:bg-gradient-to-b from-transparent delay-500 to-[#4c1313] absolute flex items-end w-[-webkit-fill-available] h-full bottom-0">
            <div class="h-[33%] hidden group-hover:block overflow-y-auto text-white break-words">
              <div class="flex flex-row gap-4">
                <div class="flex flex-row text-base">{{item.likes}} <img class="ml-2" src="assets/images/icons/heart-white.svg" alt="" /></div>
                <div class="flex flex-row text-base">{{item.comment}} <img class="ml-2" src="assets/images/icons/comment-white.svg" alt="" /></div>
                <div *ngIf="item.view !== ''" class="flex flex-row text-base">{{item.view}} <img class="ml-3" src="assets/images/icons/view-white.svg" alt="" /></div>
              </div>
              <p [innerHTML]="item.description"></p>
            </div>
          </div>
        </a>
      </div>
    </section>
<!--    <section class="bg-white py-10 px-4">-->
<!--      <div class="text-center font-semibold text-[32px] mb-8">Our Tiktok</div>-->
<!--      <div class="grid sm:grid-cols-2 grid-cols-1 lg:grid-cols-3 gap-5 mb-10">-->
<!--        <a href="{{ item.video_url }}" target="_blank" *ngFor="let item of listTiktokPost"-->
<!--           class="shadow-xl relative h-[400px] group hover:before:opacity-70">-->
<!--          <div class="overflow-hidden max-h-[400px]">-->
<!--            <img loading="lazy"-->
<!--                 class="transition-transform transform-gpu group-hover:scale-105 duration-700 w-full h-auto"-->
<!--                 src="{{ item.image ? item.image : 'assets/images/blank-image.jpg' }}" alt="{{item.title}}"/>-->
<!--          </div>-->
<!--          <div-->
<!--            class="px-8 pb-8 transform transition-transform transform-gpu transition-all duration-700 group-hover:bg-gradient-to-b from-transparent delay-500 to-[#4c1313] absolute flex items-end w-[-webkit-fill-available] h-full bottom-0">-->
<!--            <div class="h-[33%] hidden group-hover:block overflow-y-auto text-white break-words">-->
<!--              <p [innerHTML]="item.description"></p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </a>-->
<!--      </div>-->
<!--    </section>-->
<!--    <section class="bg-white py-10 px-4">-->
<!--      <div class="text-center font-semibold text-[32px] mb-8">Our Youtube</div>-->
<!--      <div class="grid sm:grid-cols-2 grid-cols-1 lg:grid-cols-3 gap-5 mb-10">-->
<!--        <a href="{{ item.video_url }}" target="_blank" *ngFor="let item of listYoutubePost"-->
<!--           class="shadow-xl relative h-[400px] group hover:before:opacity-70">-->
<!--          <div class="overflow-hidden max-h-[400px]">-->
<!--            <img loading="lazy"-->
<!--                 class="transition-transform transform-gpu group-hover:scale-105 duration-700 w-full h-auto"-->
<!--                 src="{{ item.image ? item.image : 'assets/images/blank-image.jpg' }}" alt="{{item.title}}"/>-->
<!--          </div>-->
<!--          <div-->
<!--            class="px-8 pb-8 transform transition-transform transform-gpu transition-all duration-700 group-hover:bg-gradient-to-b from-transparent delay-500 to-[#4c1313] absolute flex items-end w-[-webkit-fill-available] h-full bottom-0">-->
<!--            <div class="h-[33%] hidden group-hover:block overflow-y-auto text-white break-words">-->
<!--              <p [innerHTML]="item.description"></p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </a>-->
<!--      </div>-->
<!--    </section>-->
  </div>
</div>
